import request from '@/utils/request'

const api = {
  // 小时报表chart
  hourChart: '/report/hourTrend',
  // 小时报表report
  hourReport: '/report/hourReport',
  // 漏斗报表
  funnelReport: '/filler_report/report',
  // 综合报表 chart
  synthChart: '/report/chart',
  // 综合报表report
  synthReport: '/report/fullReport',
  // 获取综合报表搜索模板
  getReportTemplateList: '/reportTemplate/getReportTemplateList',
  // 编辑新建搜索模板
  insertReportTemplate: '/reportTemplate/insertReportTemplate',
  // 删除模板
  deleteReportTemplateSts: '/reportTemplate/updateReportTemplateSts',
  // 媒体报表report
  getMediaReport: '/report/getNewMediaReport',
  // 获取媒体报表维度、指标、选项数组字段
  getMediaReportRuleVo: '/report/getMediaReportRuleVo',
  // 媒体报表修改分成比例
  updateMediaReportInfo: '/place/updateMediaReportInfo',
  // 用户行为报表chart
  userActionChart: '/userBehavior/trend',
  // 用户行为报表report
  userActionReport: '/userBehavior/report',
  // 预警列表
  getWarningByUser: '/earlyWarning/getWarningByUser',
  // 预警报表report
  getWarningReportPage: '/earlyWarning/getWarningReportPage',
  // 预警报表rulepage
  getWarningRulePage: '/earlyWarning/getWarningPage',
  // 修改预警信息
  updateWarning: '/earlyWarning/updateWarning',
  addWarning: '/earlyWarning/addWarning',
  // 删除预警
  deleteWarning: '/earlyWarning/deleteWarning',
  // 获取站内信列表
  getSubList: '/user/getSubList',
  // 直投报表report
  getAdPlanReport: '/admanage/adPlanReport',
  // 广告下拉框
  getadList: '/admanage/ad_list',
  // 获取广告计划列表
  getAdPlanList: '/admanage/ad_plan_list_2',
  // 获取广告组下来列表
  getAdGroupList: '/admanage/ad_plan_group_list_2',
  // 获取留存报表
  getRetentionReport: '/userPrice/liucun ',
  // 获取ltv报表
  getLtvRepport: '/userPrice/ltv',
  // 获取留存chart
  getRetentionChart: '/userPrice/liucuntrend',
  // 获取ltvchart
  getLtvChart: '/userPrice/ltvtrend',
  // 媒体账户下拉框
  mediaAccountList: '/account/mediaAccountList',
  // 媒体结算列表 应用
  appSettlementPager: '/settlement/appSettlementPager',
  // 媒体结算列表 广告位
  placeSettlementPager: '/settlement/placeSettlementPager',
  // 媒体结算列表 广告平台
  platSettlementPager: 'settlement/platSettlementPager',
  // 应用下拉框
  appList: '/account/appList',
  // 修改结算数值和发布状态 包括批量操作
  updateDataDetailsInfo: '/account/updateDataDetailsInfo',
  // 媒体结算 列表发布、回收
  updateSts: '/settlement/updateSts',
  // 媒体结算，广告账户下拉
  getPlatAccounts: '/plat/accountsByPlat',
  // 媒体结算 批量发布、回收
  batchUpdateSts: '/settlement/batchUpdateSts',
  // 媒体结算 列表配置
  updateProportions: 'settlement/updateProportions',
  // 媒体结算 批量配置
  batchUpdateProportions: 'settlement/batchUpdateProportions',

  // 授权广告平台 -下拉框
  adxPlatList: '/costReport/platList',
  // 授权广告平台账户-下拉框
  adxPlatAccountList: '/costReport/platAccountList',
  // 授权广告源-下拉框
  dspPlaceList: '/costReport/dspPlaceList',
  // 消耗报表
  adxCostpager: '/costReport/pager',
  // 总金额+余额
  getAmountInfo: '/costReport/getAmountInfo',
  // 充值记录
  fundDetails: '/costReport/fundDetails'
}

export default api

export function getHourChart (data) {
  return request({
    url: api.hourChart,
    method: 'post',
    data
  })
}
export function getHourReport (data) {
  return request({
    url: api.hourReport,
    method: 'post',
    data
  })
}

export function getFunnelReport (data) {
  return request({
    url: api.funnelReport,
    method: 'post',
    data
  })
}

export function getSynthChart (data) {
  return request({
    url: api.synthChart,
    method: 'post',
    data
  })
}

export function getSynthReport (data, cancelToken) {
  return request({
    url: api.synthReport,
    method: 'post',
    data,
    cancelToken
  })
}

export function getReportTemplateList () {
  return request({
    url: api.getReportTemplateList,
    method: 'post'
  })
}

export function insertReportTemplate (data) {
  return request({
    url: api.insertReportTemplate,
    method: 'post',
    data
  })
}
export function deleteReportTemplateSts (data) {
  return request({
    url: api.deleteReportTemplateSts,
    method: 'post',
    data
  })
}

export function getMediaReport (data) {
  return request({
    url: api.getMediaReport,
    method: 'post',
    data
  })
}

export function getMediaReportRuleVo () {
  return request({
    url: api.getMediaReportRuleVo,
    method: 'post'
  })
}
export function updateMediaReportInfo (data) {
  return request({
    url: api.updateMediaReportInfo,
    method: 'post',
    data
  })
}

export function userActionChart (data) {
  return request({
    url: api.userActionChart,
    method: 'post',
    data
  })
}

export function userActionReport (data) {
  return request({
    url: api.userActionReport,
    method: 'post',
    data
  })
}

export function getWarningByUser (data) {
  return request({
    url: api.getWarningByUser,
    method: 'post',
    data
  })
}

export function getWarningReportPage (data) {
  return request({
    url: api.getWarningReportPage,
    method: 'post',
    data
  })
}

export function getWarningRulePage (data) {
  return request({
    url: api.getWarningRulePage,
    method: 'post',
    data
  })
}
export function updateWarning (data) {
  return request({
    url: api.updateWarning,
    method: 'post',
    data
  })
}

export function addWarning (data) {
  return request({
    url: api.addWarning,
    method: 'post',
    data
  })
}
export function deleteWarning (data) {
  return request({
    url: api.deleteWarning,
    method: 'post',
    data
  })
}

export function getSubList (data) {
  return request({
    url: api.getSubList,
    method: 'get',
    data
  })
}

export function getAdPlanReport (data) {
  return request({
    url: api.getAdPlanReport,
    method: 'post',
    data
  })
}

export function getadList (data) {
  return request({
    url: api.getadList,
    method: 'post',
    data
  })
}

export function getAdPlanList (data) {
  return request({
    url: api.getAdPlanList,
    method: 'post',
    data
  })
}
export function getAdGroupList (data) {
  return request({
    url: api.getAdGroupList,
    method: 'post',
    data
  })
}
export function getRetentionReport (data) {
  return request({
    url: api.getRetentionReport,
    method: 'post',
    data
  })
}
export function getLtvRepport (data) {
  return request({
    url: api.getLtvRepport,
    method: 'post',
    data
  })
}
export function getRetentionChart (data) {
  return request({
    url: api.getRetentionChart,
    method: 'post',
    data
  })
}
export function getLtvChart (data) {
  return request({
    url: api.getLtvChart,
    method: 'post',
    data
  })
}
export function mediaAccountList (data) {
  return request({
    url: api.mediaAccountList,
    method: 'post',
    data
  })
}

export function appList (data) {
  return request({
    url: api.appList,
    method: 'post',
    data
  })
}
export function updateDataDetailsInfo (data) {
  return request({
    url: api.updateDataDetailsInfo,
    method: 'post',
    data
  })
}

export function updateSts (data) {
  return request({
    url: api.updateSts,
    method: 'post',
    data
  })
}

export function appSettlementPager (data) {
  return request({
    url: api.appSettlementPager,
    method: 'post',
    data
  })
}

export function placeSettlementPager (data) {
  return request({
    url: api.placeSettlementPager,
    method: 'post',
    data
  })
}

export function platSettlementPager (data) {
  return request({
    url: api.platSettlementPager,
    method: 'post',
    data
  })
}

export function getPlatAccounts (data) {
  return request({
    url: api.getPlatAccounts,
    method: 'post',
    data
  })
}

export function batchUpdateSts (data) {
  return request({
    url: api.batchUpdateSts,
    method: 'post',
    data
  })
}

export function updateProportions (data) {
  return request({
    url: api.updateProportions,
    method: 'post',
    data
  })
}

export function batchUpdateProportions (data) {
  return request({
    url: api.batchUpdateProportions,
    method: 'post',
    data
  })
}

export function adxPlatList () {
  return request({
    url: api.adxPlatList,
    method: 'get'
  })
}

export function adxPlatAccountList () {
  return request({
    url: api.adxPlatAccountList,
    method: 'get'
  })
}

export function dspPlaceList () {
  return request({
    url: api.dspPlaceList,
    method: 'get'
  })
}

export function getAmountInfo () {
  return request({
    url: api.getAmountInfo,
    method: 'get'
  })
}

export function adxCostpager (data) {
  return request({
    url: api.adxCostpager,
    method: 'post',
    data
  })
}

export function fundDetails () {
  return request({
    url: api.fundDetails,
    method: 'get'
  })
}
