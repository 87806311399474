<template>
  <div class="warning-rule-table-container">
    <div class="control">
      <a-button @click="handleAddRule" type="primary"><a-icon type="plus" />预警规则</a-button>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :data-source="dataList"
        :loading="isLoading"
        bordered
      >
        <!-- 预警名称 -->
        <a-table-column align="left" dataIndex="name" :width="100">
          <span slot="title">预警名称</span>
        </a-table-column>
        <!-- 创建账户 -->
        <a-table-column v-if="role === 'SUPER_ADMIN'" align="left" dataIndex="company" :width="100">
          <span slot="title">创建账户</span>
        </a-table-column>
        <!-- 最近预警时间 -->
        <a-table-column align="left" dataIndex="noticeDate" :width="100">
          <span slot="title">最近预警时间</span>
          <template slot-scope="noticeDate">
            {{ noticeDate ? noticeDate : '-' }}
          </template>
        </a-table-column>
        <!-- 预警对象 -->
        <a-table-column align="left" dataIndex="target" :width="100">
          <span slot="title">预警对象</span>
          <template slot-scope="target">
            {{ target === '1' ? '应用' : '广告位' }}
          </template>
        </a-table-column>
        <!-- 预警频率 -->
        <a-table-column align="left" dataIndex="frequency" :width="100">
          <span slot="title">预警频率</span>
          <template slot-scope="frequency">
            {{ frequency === 1 ? '天级' : '小时级' }}
          </template>
        </a-table-column>
        <!-- 预警方式 -->
        <a-table-column align="left" dataIndex="noticeType" :width="100">
          <span slot="title">预警方式</span>
          <template slot-scope="noticeType">
            {{ noticeType === 1 ? '站内信' : noticeType === 2 ? '邮箱' : noticeType === 3 ? '站内信 + 邮箱' : '-' }}
          </template>
        </a-table-column>
        <!-- 预警状态 -->
        <a-table-column align="left" dataIndex="sts" :width="100">
          <span slot="title">预警状态</span>
          <template slot-scope="sts, record">
            <a-switch size="small" @change="handleChangeSts($event, record)" :checked="sts === 'A'"></a-switch>
          </template>
        </a-table-column>
        <!-- 操作 -->
        <a-table-column align="left" :width="100">
          <span slot="title">操作</span>
          <div class="action-slot" slot-scope="record">
            <span class="btn" @click="handleEditWarning(record)">编辑</span>
            <span class="line">|</span>
            <a-popconfirm title="确认删除该预警?" ok-text="是" cancel-text="否" @confirm="handleDelete(record)">
              <span class="btn">删除</span>
            </a-popconfirm>
          </div>
        </a-table-column>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
    </div>
    <m-empty v-if="dataList.length === 0" style="height: 80vh">
      <template #description>暂无数据 </template>
    </m-empty>
  </div>
</template>

<script>
import { getWarningRulePage, updateWarning, deleteWarning } from '@/api/reportdatas'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      dataList: [],
      isLoading: false,
      searchquery: {
        page: 1,
        limit: 10
      },
      total: 0
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      audit: (state) => state.user.audit
    })
  },
  methods: {
    // 供父级调用
    dealquery (query) {
      this.searchquery.page = 1
      this.searchquery = { ...this.searchquery, ...query }
      this.getTableData()
    },
    async getTableData () {
      this.isLoading = true
      const resp = await getWarningRulePage(this.searchquery)
      this.dataList = resp.data ? resp.data.items : []
      this.total = resp.data ? resp.data.total : 0
      this.isLoading = false
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    },
    // 修改预警状态
    async handleChangeSts (e, item) {
      item.sts = e ? 'A' : 'S'
      const resp = await updateWarning(item)
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getTableData()
      }
    },
    // 删除预警
    async handleDelete (item) {
      const resp = await deleteWarning({ id: item.id })
      if (resp.code === 200) {
        this.$message.success('删除成功！')
        this.searchquery.page = 1
        this.getTableData()
      }
    },
    // 修改预警跳转
    handleEditWarning (item) {
      // this.$router.push({
      //   name: 'warningupdate',
      //   params: { query: item, searchquery: this.searchquery }
      // })
      this.$emit('handleEditWarning', { query: item, isAdd: false })
    },
    // 添加预警跳转
    handleAddRule () {
      // this.$router.push({
      //   name: 'warningadd',
      //   params: { searchquery: this.searchquery },
      // })
      this.$emit('handleEditWarning', { query: {}, isAdd: true })
    }
  }
}
</script>

<style lang="less">
.warning-rule-table-container {
  margin-top: 10px;
  min-height: 80vh;
  .control {
    padding: 0 20px 10px 0px;
  }
  .table {
    .ant-table-thead tr th span {
      color: #000;
    }
    .ant-table-tbody tr td {
      // font-size: 12px !important;
      .ant-switch-checked {
        background: #13ce66;
      }
    }
    .action-slot{
      .btn{
        color: @primary-color;
        cursor: pointer;
      }
      .line{
        color: #ccc;
      }
      span{
        margin-right: 10px;
      }
    }
  }
  .pagination {
    margin: 10px 0;
  }
}
</style>
